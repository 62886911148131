/* CSS for sequencing */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    right: 10px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: @color-neutral--light url("../images/banner.jpg") no-repeat;
    background-size: 100% auto;
    background-color: @color-neutral--light;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        90% 53%;
    }
    #head {
      background: url("../images/nsclogo-white.svg") no-repeat center center;
      background-size: 370px;
      height: 133px;
      .uio-app-name {
        display: none;
      }
      .head-menu {
        top: 15px;
        right: 120px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        height: 100%;
        text-align: center;
        a.logo {
          height: 100%;
          width: 100%;
        }
        .logo-wrapper {
          width: 400px;
          height: 100%;
          background: transparent;
          display: inline-block;
          margin: 0px auto;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}
#main {
  min-height: 300px;
}
#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-ancestor > span:hover:after,
  &.vrtx-ancestor > a:hover:after {
    border-color: @color-neutral--light transparent transparent transparent;
  }
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-primary;
      border-top: none !important;
      background: rgba(0, 0, 0, 0) url("../images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-primary !important;
      color: @color-light;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

.flex-box .vrtx-box-content {
  display: flex;
  justify-content: space-between;
}

#vrtx-main-content-1 {
  .vrtx-box-content div {
    background: #f5f7f8;
    padding: 1em;
    min-height: 194px;
    width: 49%;
    margin-bottom: 2%;
    &.right {
      float: right;
    }
    &.left {
      float: left;
    }
  }
  img {
    float: left;
    width: 30%;
    min-width: 30%;
  }
  h3,
  li {
    width: 67%;
    float: right;
    margin: 0;
    margin-left: 2%;
  }
  li {
    width: 62%;
    font-size: 13px;
  }
}

.uio-main ul > li::before,
.grid-container ul > li::before {
  content: "\2022";
  top: 0.02em;
  left: 0.6em;
  font-size: 2.2rem;
}

#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-orange.svg") no-repeat 0 0.2ex;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-orange.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-orange.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-orange.svg");
  }
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @footer-color;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      height: 178px;
      #head {
        background-color: @color-secondary;
        background-size: 300px;
        #header {
          img {
            padding: 0 10px 10px;
          }
          .logo-wrapper {
            display: block;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-neutral--dark none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* About - People page */
.people-wrap img {
  min-width: 100px;
  float: left !important;
}
.leader-wrap td:nth-child(even),
.personell-wrap td:nth-child(even) {
  width: 34% !important;
  padding: 10px 0px 6px 0px;
}
.leader-wrap td:nth-child(odd),
.personell-wrap td:nth-child(odd) {
  width: 16%;
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #fa9239;
@color-primary--dark: #df8320;
@color-secondary: #df8320;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #e4e4e4;
@color-neutral--air: #e5e5e5;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
